import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: '',
  component: () => import('@/views/Login'),
  hidden: true,
  meta: {
    requireAuth: false
  }
}, {
  path: '/login',
  name: '登录',
  component: () => import('@/views/Login'),
  hidden: true,
  meta: {
    requireAuth: false
  }
},
{
  path: '/home',
  name: '首页',
  component: () => import('@/views/Home'),
  redirect: '/order/list',
  children: [
    {
      path: '/order/list',
      name: '订单监管',
      component: () => import('@/views/order/list')
    },
    {
      path: '/order/examine',
      name: '审核订单（租赁）',
      component: () => import('@/views/order/list')
    },
    {
      path: '/order/examine',
      name: '售卖订单',
      component: () => import('@/views/order/list')
    },
    {
      path: '/order/examine',
      name: '逾期订单',
      component: () => import('@/views/order/list')
    },
    {
      path: '/order/examine',
      name: '退款订单',
      component: () => import('@/views/order/list')
    },
    {
      path: '/order/examine',
      name: '坏账订单',
      component: () => import('@/views/order/list')
    },
    {
      path: '/order/details',
      name: '订单详情',
      component: () => import('@/views/order/details')
    },
    {
      path: '/shop/account',
      name: '商家账号',
      component: () => import('@/views/shop/account')
    },
    {
      path: '/shop/code',
      name: '店铺二维码',
      component: () => import('@/views/shop/code')
    },
    {
      path: '/column/category',
      name: '商品分类',
      component: () => import('@/views/column/category')
    },
    {
      path: '/column/brand',
      name: '品牌管理',
      component: () => import('@/views/column/brand')
    },
    {
      path: '/power/role',
      name: '角色组',
      component: () => import('@/views/power/role')
    },
    {
      path: '/power/journal',
      name: '管理员日志',
      component: () => import('@/views/power/journal')
    },
    {
      path: '/power/administrators',
      name: '管理员管理',
      component: () => import('@/views/power/administrators')
    },
    {
      path: '/logistics/freight',
      name: '运费模板',
      component: () => import('@/views/logistics/freight')
    },
    {
      path: '/logistics/deliver',
      name: '快递列表',
      component: () => import('@/views/logistics/deliver')
    },
    {
      path: '/logistics/receive',
      name: '收获地址',
      component: () => import('@/views/logistics/receive')
    },
    {
      path: '/contract/list',
      name: '系统配置',
      component: () => import('@/views/contract/list')
    },
    {
      path: '/contract/sign',
      name: '个人资料',
      component: () => import('@/views/contract/sign')
    },
    {
      path: '/goods/list',
      name: '商品管理',
      component: () => import('@/views/goods/list')
    },
    {
      path: '/goods/leasenotice',
      name: '租赁须知',
      component: () => import('@/views/goods/leasenotice')
    },
    {
      path: '/goods/problem_type',
      name: "问题分类",
      component: () => import('@/views/goods/problem_type')
    },
    {
      path: "/goods/problem",
      name: "常见问题",
      component: () => import('@/views/goods/problem')
    },
    {
      path: "/goods/goods_hot_search",
      name: "热搜关键词",
      component: () => import('@/views/goods/goods_hot_search')
    },
    {
      path: '/coupon/list',
      name: '优惠券',
      component: () => import('@/views/coupon/list')
    },
    {
      path: '/prefecture/list',
      name: '专区/分类',
      component: () => import('@/views/prefecture/list')
    },
    {
      path: '/prefecture/goods',
      name: '专区商品',
      component: () => import('@/views/prefecture/goods')
    },
    {
      path: '/power/user',
      name: '会员列表',
      component: () => import('@/views/power/user')
    },
    {
      path: '/power/user_address',
      name: "收货地址",
      component: () => import('@/views/power/user_address')
    },
    {
      path: '/navigation/list',
      name: "导航管理",
      component: () => import('@/views/navigation/list')
    },
    {
      path: '/navigation/image',
      name: "图片管理",
      component: () => import('@/views/navigation/image')
    },
    {
      path: '/navigation/link',
      name: "页面链接",
      component: () => import('@/views/navigation/link')
    }
  ]
},

]

const router = new VueRouter({
  routes
})
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

export default router

// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
// router.beforeEach((to, from, next) => {
//   if (to.path === '/login') {
//     next();
//   } else {
//     let token = sessionStorage.getItem("duohuatoken")
//     if (!token) {
//       next('/login');
//     } else {
//       next();
//     }
//   }
// });
